<style lang="less" scoped>
.aboutUs-details {
  width: 76.7%;
  height: 100%;
  max-width: 1104px;
  margin: 0 auto;
  padding: 196px 0;
  color: rgba(222, 230, 255, 1);
  &-first {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-between;
    &-left {
      width: 49.8%;
      img {
        width: 100%;
      }
    }
    &-right {
      width: 43.1%;
      p {
        margin: 0;
        font-family: "PingFangSC-Regular", "PingFang SC";
        color: rgba(222, 230, 255, 1);
        font-weight: 400;
        &:nth-child(1) {
          font-size: 30px;
          line-height: 42px;
          letter-spacing: 4px;
        }
        &:nth-child(2) {
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 4px;
          margin: 5px 0 28px;
        }
        &:nth-child(3) {
          font-size: 16px;
          line-height: 40px;
          letter-spacing: 2px;
        }
      }
    }
  }
  .normal {
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 2px;
    margin-top: 120px;
  }
  &-third {
    margin-top: 101px;
    width: 100%;
    height: 698px;
    background: #070911;
    position: relative;
    .idc-fifth-container {
      width: 70%;
      margin-left: 15%;
      padding-top: 5%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
  /deep/
    .idc-fifth-container.swiper-container-initialized.swiper-container-horizontal
    > .swiper-button-next,
  .swiper-button-prev {
    width: 51px;
    height: 51px;
    background: rgba(222, 230, 255, 1);
    border-radius: 50%;
    color: #0f1118;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    margin-left: 50px;
    margin-right: 50px;
    &:after {
      font-size: 14px;
      font-weight: bolder;
    }
  }
}
</style>

<template>
  <div class="aboutUs-details">
    <div class="aboutUs-details-first">
      <div class="aboutUs-details-first-left">
        <img src="../assets/w1.png"
             alt="">
      </div>
      <div class="aboutUs-details-first-right">
        <p>
          榜单简介
        </p>
        <p>
          INTRODUCTION
        </p>
        <p>
          为深入贯彻落实习近平总书记重要讲话精神，奋力夺取疫情防控和实现长三角经济社会发展目标“双胜利”，在长三角城市经济协调会办公室指导下，由长三角创意经济专业委员会（简称CECC）牵头组织长三角全球创意设计大赛及其专属“锦绣江南”名品榜活动。该活动已被列入长三角城市经济协调会2020年度重点合作事项清单。
        </p>
        <img src="../assets/weibo.png"
             alt=""
             style="width: 30px; marginTop: 33px; cursor: pointer"
             @click="linkTo('https://weibo.com/u/6096290535', '_blank')">
        <img src="../assets/weixin.png"
             alt=""
             style="width: 30px; marginLeft: 30px; cursor: pointer"
             @click="linkTo('https://mp.weixin.qq.com/s/Xpwm4f_M0hJEGKGVZsf_6Q', '_blank')">
      </div>
    </div>
    <div class="aboutUs-details-second">
      <p class="normal">
        该榜单主要通过政府推荐、企业自荐与社会评荐相结合的方式，大力挖掘并集聚长三角各城市消费名品、文旅名品和农副名 品等好货资源，利用好“长三角”自身的品牌效应，并借助赛事引进全球创意设计资源，创意性营造“在线品质生活新场景”作为强化创新策源动能和原创引领功能的重要结合点，面向全球打响新生代互联网经济品牌，从而满足生产生活升级和技术场景赋能产业转型的产消需求。
      </p>
    </div>
    <div class="aboutUs-details-third">
      <div class="idc-fifth-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/j2.png"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/j2.png"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/j2.png"
                 alt="">
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <Common-activies :style="{width: '100%'}"></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import CommonActivies from '../components/common-activities-c';
import CommonFooter from '../components/common-footer';
export default {
  name: 'aboutUs-details',
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
    new Swiper('.idc-fifth-container', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    }
  },
  components: {
    CommonActivies,
    CommonFooter
  },
}
</script>
